<template>
  <div class="pdf_detail_page">
    <my-header isShowLogo="show" :logoSrc="logo"></my-header>
    <div class="tips">Double click to enter or exit full screen mode</div>
    <!-- 第四步，给滚动容器dom上加上data-simplebar属性做标记为滚动容器，data-simplebar-auto-hide="true"设置自动隐藏 -->
    <iframe :src="pdfUrl"></iframe>

    <a class="download" :href="downUrl" download target="_blank">
      <img src="../assets/images/pdf/down.png" alt="" />
    </a>
  </div>
</template>

<script>
import logo from "../assets/images/product/logo.png";
import MyHeader from "../components/MyHeader.vue";
export default {
  components: {
    MyHeader,
  },
  props: {},
  data() {
    return {
      pdfUrl: "",
      downUrl: "",
      logo,
    };
  },
  async created() {
    const query = JSON.parse(JSON.stringify(this.$route.query));
    // js截取url地址拼接后边的内容
    this.downUrl = query.pdfUrl.split("=")[1];
    this.pdfUrl = query.pdfUrl;
    // console.log(this.downUrl, "pdf文件");
  },
  mounted() {},
  computed: {},
  methods: {},
  watch: {},
};
</script>
<style lang="scss">
@import "../assets/scss/_fun.scss";
.pdf_detail_page {
  position: relative;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-image: url("../assets/images/pdf/bg1.png");
  background-repeat: no-repeat;
  background-size: cover;
  .tips {
    position: fixed;
    top: vw(80);
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 16px !important;
    font-family: Helvetica;
    opacity: 0.5;
    z-index: 9;
  }
  iframe {
    position: absolute;
    top: vw(220);
    left: 50%;
    transform: translate(-50%);
    width: 70vw;
    height: vw(810);
    border-width: 0;
  }
  .placeholder_map {
    width: vw(707);
    height: vw(502);
  }
  .download {
    position: absolute;
    right: vw(185);
    bottom: vw(50);
    cursor: pointer;
    img {
      width: vw(40);
      height: vw(40);
    }
  }
}
</style>
